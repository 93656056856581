import { default as indexz17yc7mnhFMeta } from "/app/pages/admin/role/index.vue?macro=true";
import { default as indexOhQ6NGykTuMeta } from "/app/pages/admin/user/index.vue?macro=true";
import { default as callbackFBKqDD9hoQMeta } from "/app/pages/callback.vue?macro=true";
import { default as dashboardJu37u7k0toMeta } from "/app/pages/dashboard.vue?macro=true";
import { default as index6mBOKSr1msMeta } from "/app/pages/dev/setting/app/index.vue?macro=true";
import { default as admin_45fileiIDaL5GPV7Meta } from "/app/pages/dev/table/admin-file.vue?macro=true";
import { default as settingwkfh2qysoYMeta } from "/app/pages/dev/table/setting.vue?macro=true";
import { default as indexoNJe6nApdsMeta } from "/app/pages/dev/utils/index.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as loginhHM0vSTW5jMeta } from "/app/pages/login.vue?macro=true";
import { default as logoutHaVXWqg8UAMeta } from "/app/pages/logout.vue?macro=true";
import { default as _91id_93b4P9mrunjUMeta } from "/app/pages/project/ba/bank/detail/[id].vue?macro=true";
import { default as indexjFOd9hh3MpMeta } from "/app/pages/project/ba/bank/index.vue?macro=true";
import { default as indexIG02zQLRNiMeta } from "/app/pages/project/ba/bs_column/index.vue?macro=true";
import { default as indexNwRVZdIr5TMeta } from "/app/pages/project/ba/changelog/index.vue?macro=true";
import { default as indexGI49osyLMDMeta } from "/app/pages/project/ba/column/index.vue?macro=true";
import { default as _91id_93QhsYEMcNZjMeta } from "/app/pages/project/ba/invoice/detail/[id].vue?macro=true";
import { default as indexh9rxZq6Sc7Meta } from "/app/pages/project/ba/invoice/index.vue?macro=true";
import { default as indexnKwyqTP2AGMeta } from "/app/pages/project/ba/subsidy/index.vue?macro=true";
import { default as indexCSlFK0EGtyMeta } from "/app/pages/project/ba/user/index.vue?macro=true";
import { default as indexo8wR6EvCDIMeta } from "/app/pages/project/forum/mail/index.vue?macro=true";
import { default as _91id_93TTFXUKqRseMeta } from "/app/pages/project/forum/topic/detail/[id].vue?macro=true";
import { default as indexb7jBivypCIMeta } from "/app/pages/project/forum/topic/index.vue?macro=true";
import { default as indexK3P0kvq7MiMeta } from "/app/pages/project/fukuri/index.vue?macro=true";
import { default as recommendvGDepKA9LDMeta } from "/app/pages/project/fukuri/recommend.vue?macro=true";
import { default as index9IVBk4irPkMeta } from "/app/pages/project/subsidy-support/contact/index.vue?macro=true";
import { default as indexefFPuk9E1wMeta } from "/app/pages/setting/ba/config/index.vue?macro=true";
import { default as indexjJsrURvDu0Meta } from "/app/pages/tool/banner/index.vue?macro=true";
import { default as indexWTta3PkdmXMeta } from "/app/pages/tool/column/index.vue?macro=true";
import { default as indexovFmTHZi7wMeta } from "/app/pages/tool/media/index.vue?macro=true";
export default [
  {
    name: indexz17yc7mnhFMeta?.name ?? "admin-role",
    path: indexz17yc7mnhFMeta?.path ?? "/admin/role",
    meta: indexz17yc7mnhFMeta || {},
    alias: indexz17yc7mnhFMeta?.alias || [],
    redirect: indexz17yc7mnhFMeta?.redirect || undefined,
    component: () => import("/app/pages/admin/role/index.vue").then(m => m.default || m)
  },
  {
    name: indexOhQ6NGykTuMeta?.name ?? "admin-user",
    path: indexOhQ6NGykTuMeta?.path ?? "/admin/user",
    meta: indexOhQ6NGykTuMeta || {},
    alias: indexOhQ6NGykTuMeta?.alias || [],
    redirect: indexOhQ6NGykTuMeta?.redirect || undefined,
    component: () => import("/app/pages/admin/user/index.vue").then(m => m.default || m)
  },
  {
    name: callbackFBKqDD9hoQMeta?.name ?? "callback",
    path: callbackFBKqDD9hoQMeta?.path ?? "/callback",
    meta: callbackFBKqDD9hoQMeta || {},
    alias: callbackFBKqDD9hoQMeta?.alias || [],
    redirect: callbackFBKqDD9hoQMeta?.redirect || undefined,
    component: () => import("/app/pages/callback.vue").then(m => m.default || m)
  },
  {
    name: dashboardJu37u7k0toMeta?.name ?? "dashboard",
    path: dashboardJu37u7k0toMeta?.path ?? "/dashboard",
    meta: dashboardJu37u7k0toMeta || {},
    alias: dashboardJu37u7k0toMeta?.alias || [],
    redirect: dashboardJu37u7k0toMeta?.redirect || undefined,
    component: () => import("/app/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: index6mBOKSr1msMeta?.name ?? "dev-setting-app",
    path: index6mBOKSr1msMeta?.path ?? "/dev/setting/app",
    meta: index6mBOKSr1msMeta || {},
    alias: index6mBOKSr1msMeta?.alias || [],
    redirect: index6mBOKSr1msMeta?.redirect || undefined,
    component: () => import("/app/pages/dev/setting/app/index.vue").then(m => m.default || m)
  },
  {
    name: admin_45fileiIDaL5GPV7Meta?.name ?? "dev-table-admin-file",
    path: admin_45fileiIDaL5GPV7Meta?.path ?? "/dev/table/admin-file",
    meta: admin_45fileiIDaL5GPV7Meta || {},
    alias: admin_45fileiIDaL5GPV7Meta?.alias || [],
    redirect: admin_45fileiIDaL5GPV7Meta?.redirect || undefined,
    component: () => import("/app/pages/dev/table/admin-file.vue").then(m => m.default || m)
  },
  {
    name: settingwkfh2qysoYMeta?.name ?? "dev-table-setting",
    path: settingwkfh2qysoYMeta?.path ?? "/dev/table/setting",
    meta: settingwkfh2qysoYMeta || {},
    alias: settingwkfh2qysoYMeta?.alias || [],
    redirect: settingwkfh2qysoYMeta?.redirect || undefined,
    component: () => import("/app/pages/dev/table/setting.vue").then(m => m.default || m)
  },
  {
    name: indexoNJe6nApdsMeta?.name ?? "dev-utils",
    path: indexoNJe6nApdsMeta?.path ?? "/dev/utils",
    meta: indexoNJe6nApdsMeta || {},
    alias: indexoNJe6nApdsMeta?.alias || [],
    redirect: indexoNJe6nApdsMeta?.redirect || undefined,
    component: () => import("/app/pages/dev/utils/index.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect || undefined,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginhHM0vSTW5jMeta?.name ?? "login",
    path: loginhHM0vSTW5jMeta?.path ?? "/login",
    meta: loginhHM0vSTW5jMeta || {},
    alias: loginhHM0vSTW5jMeta?.alias || [],
    redirect: loginhHM0vSTW5jMeta?.redirect || undefined,
    component: () => import("/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: logoutHaVXWqg8UAMeta?.name ?? "logout",
    path: logoutHaVXWqg8UAMeta?.path ?? "/logout",
    meta: logoutHaVXWqg8UAMeta || {},
    alias: logoutHaVXWqg8UAMeta?.alias || [],
    redirect: logoutHaVXWqg8UAMeta?.redirect || undefined,
    component: () => import("/app/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: _91id_93b4P9mrunjUMeta?.name ?? "project-ba-bank-detail-id",
    path: _91id_93b4P9mrunjUMeta?.path ?? "/project/ba/bank/detail/:id",
    meta: _91id_93b4P9mrunjUMeta || {},
    alias: _91id_93b4P9mrunjUMeta?.alias || [],
    redirect: _91id_93b4P9mrunjUMeta?.redirect || undefined,
    component: () => import("/app/pages/project/ba/bank/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: indexjFOd9hh3MpMeta?.name ?? "project-ba-bank",
    path: indexjFOd9hh3MpMeta?.path ?? "/project/ba/bank",
    meta: indexjFOd9hh3MpMeta || {},
    alias: indexjFOd9hh3MpMeta?.alias || [],
    redirect: indexjFOd9hh3MpMeta?.redirect || undefined,
    component: () => import("/app/pages/project/ba/bank/index.vue").then(m => m.default || m)
  },
  {
    name: indexIG02zQLRNiMeta?.name ?? "project-ba-bs_column",
    path: indexIG02zQLRNiMeta?.path ?? "/project/ba/bs_column",
    meta: indexIG02zQLRNiMeta || {},
    alias: indexIG02zQLRNiMeta?.alias || [],
    redirect: indexIG02zQLRNiMeta?.redirect || undefined,
    component: () => import("/app/pages/project/ba/bs_column/index.vue").then(m => m.default || m)
  },
  {
    name: indexNwRVZdIr5TMeta?.name ?? "project-ba-changelog",
    path: indexNwRVZdIr5TMeta?.path ?? "/project/ba/changelog",
    meta: indexNwRVZdIr5TMeta || {},
    alias: indexNwRVZdIr5TMeta?.alias || [],
    redirect: indexNwRVZdIr5TMeta?.redirect || undefined,
    component: () => import("/app/pages/project/ba/changelog/index.vue").then(m => m.default || m)
  },
  {
    name: indexGI49osyLMDMeta?.name ?? "project-ba-column",
    path: indexGI49osyLMDMeta?.path ?? "/project/ba/column",
    meta: indexGI49osyLMDMeta || {},
    alias: indexGI49osyLMDMeta?.alias || [],
    redirect: indexGI49osyLMDMeta?.redirect || undefined,
    component: () => import("/app/pages/project/ba/column/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93QhsYEMcNZjMeta?.name ?? "project-ba-invoice-detail-id",
    path: _91id_93QhsYEMcNZjMeta?.path ?? "/project/ba/invoice/detail/:id",
    meta: _91id_93QhsYEMcNZjMeta || {},
    alias: _91id_93QhsYEMcNZjMeta?.alias || [],
    redirect: _91id_93QhsYEMcNZjMeta?.redirect || undefined,
    component: () => import("/app/pages/project/ba/invoice/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: indexh9rxZq6Sc7Meta?.name ?? "project-ba-invoice",
    path: indexh9rxZq6Sc7Meta?.path ?? "/project/ba/invoice",
    meta: indexh9rxZq6Sc7Meta || {},
    alias: indexh9rxZq6Sc7Meta?.alias || [],
    redirect: indexh9rxZq6Sc7Meta?.redirect || undefined,
    component: () => import("/app/pages/project/ba/invoice/index.vue").then(m => m.default || m)
  },
  {
    name: indexnKwyqTP2AGMeta?.name ?? "project-ba-subsidy",
    path: indexnKwyqTP2AGMeta?.path ?? "/project/ba/subsidy",
    meta: indexnKwyqTP2AGMeta || {},
    alias: indexnKwyqTP2AGMeta?.alias || [],
    redirect: indexnKwyqTP2AGMeta?.redirect || undefined,
    component: () => import("/app/pages/project/ba/subsidy/index.vue").then(m => m.default || m)
  },
  {
    name: indexCSlFK0EGtyMeta?.name ?? "project-ba-user",
    path: indexCSlFK0EGtyMeta?.path ?? "/project/ba/user",
    meta: indexCSlFK0EGtyMeta || {},
    alias: indexCSlFK0EGtyMeta?.alias || [],
    redirect: indexCSlFK0EGtyMeta?.redirect || undefined,
    component: () => import("/app/pages/project/ba/user/index.vue").then(m => m.default || m)
  },
  {
    name: indexo8wR6EvCDIMeta?.name ?? "project-forum-mail",
    path: indexo8wR6EvCDIMeta?.path ?? "/project/forum/mail",
    meta: indexo8wR6EvCDIMeta || {},
    alias: indexo8wR6EvCDIMeta?.alias || [],
    redirect: indexo8wR6EvCDIMeta?.redirect || undefined,
    component: () => import("/app/pages/project/forum/mail/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93TTFXUKqRseMeta?.name ?? "project-forum-topic-detail-id",
    path: _91id_93TTFXUKqRseMeta?.path ?? "/project/forum/topic/detail/:id",
    meta: _91id_93TTFXUKqRseMeta || {},
    alias: _91id_93TTFXUKqRseMeta?.alias || [],
    redirect: _91id_93TTFXUKqRseMeta?.redirect || undefined,
    component: () => import("/app/pages/project/forum/topic/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: indexb7jBivypCIMeta?.name ?? "project-forum-topic",
    path: indexb7jBivypCIMeta?.path ?? "/project/forum/topic",
    meta: indexb7jBivypCIMeta || {},
    alias: indexb7jBivypCIMeta?.alias || [],
    redirect: indexb7jBivypCIMeta?.redirect || undefined,
    component: () => import("/app/pages/project/forum/topic/index.vue").then(m => m.default || m)
  },
  {
    name: indexK3P0kvq7MiMeta?.name ?? "project-fukuri",
    path: indexK3P0kvq7MiMeta?.path ?? "/project/fukuri",
    meta: indexK3P0kvq7MiMeta || {},
    alias: indexK3P0kvq7MiMeta?.alias || [],
    redirect: indexK3P0kvq7MiMeta?.redirect || undefined,
    component: () => import("/app/pages/project/fukuri/index.vue").then(m => m.default || m)
  },
  {
    name: recommendvGDepKA9LDMeta?.name ?? "project-fukuri-recommend",
    path: recommendvGDepKA9LDMeta?.path ?? "/project/fukuri/recommend",
    meta: recommendvGDepKA9LDMeta || {},
    alias: recommendvGDepKA9LDMeta?.alias || [],
    redirect: recommendvGDepKA9LDMeta?.redirect || undefined,
    component: () => import("/app/pages/project/fukuri/recommend.vue").then(m => m.default || m)
  },
  {
    name: index9IVBk4irPkMeta?.name ?? "project-subsidy-support-contact",
    path: index9IVBk4irPkMeta?.path ?? "/project/subsidy-support/contact",
    meta: index9IVBk4irPkMeta || {},
    alias: index9IVBk4irPkMeta?.alias || [],
    redirect: index9IVBk4irPkMeta?.redirect || undefined,
    component: () => import("/app/pages/project/subsidy-support/contact/index.vue").then(m => m.default || m)
  },
  {
    name: indexefFPuk9E1wMeta?.name ?? "setting-ba-config",
    path: indexefFPuk9E1wMeta?.path ?? "/setting/ba/config",
    meta: indexefFPuk9E1wMeta || {},
    alias: indexefFPuk9E1wMeta?.alias || [],
    redirect: indexefFPuk9E1wMeta?.redirect || undefined,
    component: () => import("/app/pages/setting/ba/config/index.vue").then(m => m.default || m)
  },
  {
    name: indexjJsrURvDu0Meta?.name ?? "tool-banner",
    path: indexjJsrURvDu0Meta?.path ?? "/tool/banner",
    meta: indexjJsrURvDu0Meta || {},
    alias: indexjJsrURvDu0Meta?.alias || [],
    redirect: indexjJsrURvDu0Meta?.redirect || undefined,
    component: () => import("/app/pages/tool/banner/index.vue").then(m => m.default || m)
  },
  {
    name: indexWTta3PkdmXMeta?.name ?? "tool-column",
    path: indexWTta3PkdmXMeta?.path ?? "/tool/column",
    meta: indexWTta3PkdmXMeta || {},
    alias: indexWTta3PkdmXMeta?.alias || [],
    redirect: indexWTta3PkdmXMeta?.redirect || undefined,
    component: () => import("/app/pages/tool/column/index.vue").then(m => m.default || m)
  },
  {
    name: indexovFmTHZi7wMeta?.name ?? "tool-media",
    path: indexovFmTHZi7wMeta?.path ?? "/tool/media",
    meta: indexovFmTHZi7wMeta || {},
    alias: indexovFmTHZi7wMeta?.alias || [],
    redirect: indexovFmTHZi7wMeta?.redirect || undefined,
    component: () => import("/app/pages/tool/media/index.vue").then(m => m.default || m)
  }
]