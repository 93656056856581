export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"hid":"description","name":"description","content":"ba-admin nuxt3"},{"property":"og:site_name","content":"ba-admin"},{"hid":"og:type","property":"og:type","content":"website"},{"hid":"og:url","property":"og:url","content":"https://www.kokopelli-inc.com"},{"hid":"og:title","property":"og:title","content":"ba-admin"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/favicon.ico"},{"rel":"canonical","href":"https://www.kokopelli-inc.com"}],"style":[],"script":[],"noscript":[],"title":"BigAdvance","titleTemplate":"%s | BA-ADMIN"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"