interface State {
  windowWidth: number | null;
  windowHeight: number | null;
  globalNavi: boolean;
  isAdmin: boolean;
  overlay: boolean;
  breadcrumb: any;
  searchCondition: { url: string; values: any };
  paginationInfo: {
    totalPages: number;
    totalRows: number;
    currentPage: number;
  };
  toastMessages: any;
  isMobile: boolean;
  device: string;
  mobileNavi: boolean;
  showNavDrawe: boolean;
  checkedIds: string[]; // チェックボックスデータ,
  // CSSの設定が完了しているか否か
  setedStyleProperty: boolean;
  token: string;
  permission: string;
}

export const useBaseStore = (): any => {
  const state = useState<State>('base_store', () => ({
    windowWidth: 0,
    windowHeight: 0,
    globalNavi: false,
    isAdmin: false,
    overlay: false,
    breadcrumb: {
      pathname: '',
      title: '',
      subTitle: '',
      links: [{ text: '', href: '' }]
    },
    searchCondition: {
      url: '',
      values: {}
    },
    paginationInfo: {
      totalPages: 0,
      totalRows: 0,
      currentPage: 1
    },
    toastMessages: {
      success: {
        is_show: false,
        message: ''
      },
      error: {
        is_show: false,
        message: ''
      }
    },
    isMobile: false,
    device: 'pc',
    mobileNavi: false,
    showNavDrawe: false,
    checkedIds: [],
    setedStyleProperty: false,
    token: '',
    permission: ''
  }));

  const onResize = (): void => {
    // xs
    if (process.client) {
      state.value.isMobile = window.innerWidth < 600;
      if (window.innerWidth > 1024) {
        state.value.device = 'pc';
      } else if (window.innerWidth > 480) {
        state.value.device = 'tablet';
      } else {
        state.value.device = 'sp';
      }
      state.value.windowWidth = window.innerWidth;
      state.value.windowHeight = window.innerHeight;
    }
  };

  // CSSの設定完了状態
  const setStyle = (done: boolean): void => {
    state.value.setedStyleProperty = done;
  };

  // 管理者設定
  const setAdmin = (isAdmin: boolean): void => {
    state.value.isAdmin = isAdmin;
  };

  // オーバーレイ設定
  const setOverlay = (overlay: boolean): void => {
    state.value.overlay = overlay;
  };

  // グローバルメニューの表示切替
  const showGlobalNavi = (show: boolean): void => {
    state.value.globalNavi = show;
  };

  // 一覧の検索条件を保持
  const setConditions = <T extends {}>(url: string, form: T): void => {
    state.value.searchCondition.url = url;
    state.value.searchCondition.values = form;
  };

  // 検索条件state取得
  const getConditions = (url: string): any => {
    if (state.value.searchCondition.url === url) {
      return state.value.searchCondition.values;
    }
    return false;
  };

  // 一覧の検索条件をクリア
  const resetConditions = (): void => {
    state.value.searchCondition.url = '';
    state.value.searchCondition.values = {};
  };

  // 一覧のページ情報
  const setPagenationInfo = (totalPages: number, totalRows: number, currentPage: number): void => {
    state.value.paginationInfo.totalPages = totalPages;
    state.value.paginationInfo.totalRows = totalRows;
    state.value.paginationInfo.currentPage = currentPage;
  };

  // チェックボックスの値設定
  const setCheckedId = (id: string, add: boolean): void => {
    if (add) {
      state.value.checkedIds.push(id);
    } else {
      state.value.checkedIds = state.value.checkedIds.filter((val) => val !== id);
    }
  };

  // チェックボックスの値クリア
  const resetCheckedId = (): void => {
    state.value.checkedIds = [];
  };

  // トーストメッセージ設定
  const addToastMessage = (message: any, type?: string): void => {
    const t = type ?? 'success';
    const obj: any = { ...state.value.toastMessages[t] };
    let msg = 'エラーが発生しました';
    if (typeof message === 'object' && message.body) {
      msg = message.body;
    }
    if (typeof message === 'string') {
      msg = message;
    }
    obj[msg] = true;
    state.value.toastMessages[t] = obj;
  };

  // 権限名称取得(=ルート名称)
  const setPermissionName = (permission: string): void => {
    state.value.permission = permission;
  };

  return {
    setStyle,
    onResize,
    showGlobalNavi,
    setConditions,
    getConditions,
    resetConditions,
    setPagenationInfo,
    setAdmin,
    setOverlay,
    setCheckedId,
    resetCheckedId,
    addToastMessage,
    setPermissionName,
    state
  };
};
