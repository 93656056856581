/**
 * データ検索で利用
 *
 * APIの実行
 * ページング処理
 * @module compositions/base-search
 *
 */
import { useRequest } from '@/composables/base-request';
import { useBaseStore } from '@/composables/base-store';
import { IPaigingData } from '@/types';

/**
 *
 * @interface State
 *
 * method:string, APIのmethod
 * url: string; APIのパス
 * saveConditions?: boolean, 検索条件を保持するか
 * useSaveConditions?: boolean, 保持している検索条件ではなくパラメータの値をAPIのクエリパラメターとするか
 * ignorePageing: boolean; // ページングの条件を設定しない
 * pagingParams: { rows: number; page: number }; 検索条件
 * loaded: boolean;
 * items: any; apiのResponseのデータ情報
 * doToast: boolean; トースト表示
 * pagingData: IPaigingData; apiのResponseのページング情報
 * overlay: boolean;オーバーレイ表示
 */
interface State {
  method: string;
  url: string;
  saveConditions: boolean;
  useSaveConditions: boolean;
  ignorePageing: boolean;
  pagingParams: { rows: number | string; page: number };
  loaded: boolean;
  items: any;
  item: {};
  doToast: boolean;
  pagingData: IPaigingData;
  overlay: boolean;
  payload: {};
}

/**
 * 検索処理
 *
 * @return {*}  {*}
 */
const Search = (): any => {
  const state = reactive<State>({
    method: 'get',
    url: '',
    saveConditions: true,
    useSaveConditions: false,
    ignorePageing: true,
    pagingParams: { rows: 100, page: 1 },
    loaded: false,
    items: [],
    item: {},
    doToast: true,
    pagingData: {
      total_rows: 0,
      total_pages: 0,
      page: 1,
      rows: 0,
      is_first_page: true,
      prev_page: null,
      is_last_page: false,
      next_page: null
    },
    overlay: true,
    payload: {}
  });

  const baseStore = useBaseStore();
  const { ofetchApi } = useRequest();

  /**
   * 検索APIの設定
   *
   * @param {string} url APIのURL
   * @param {{
   *       method:string, APIのmethod
   *       ignorePageing: boolean, ページングの条件を設定しない
   *       page: { rows: number | string; page: number },
   *       saveConditions: boolean 検索条件を保持するか（APIのURLをキーに条件を保存する）
   *       useSaveConditions?: boolean, 保持している検索条件ではなくパラメータの値をAPIのクエリパラメターとするか
   *       doToast?: boolean, トースト表示をするか
   *       doOverlay?: boolean, オーバーレイ
   *     }} [option]
   */
  const setSearchConfig = (
    url: string,
    option?: {
      method: string;
      ignorePageing: boolean;
      page: { rows: number | string; page: number };
      saveConditions: boolean;
      useSaveConditions: boolean;
      doToast: boolean;
      doOverlay: boolean;
    }
  ): void => {
    state.url = url;
    if (option) {
      if (option.method) {
        state.method = option.method;
      }

      if (option.ignorePageing !== undefined) {
        state.ignorePageing = option.ignorePageing;
      }

      if (option.page) {
        state.pagingParams = option.page;
      }

      if (option.saveConditions !== undefined) {
        state.saveConditions = option.saveConditions;
      }

      if (option.useSaveConditions !== undefined) {
        state.useSaveConditions = option.useSaveConditions;
      }

      if (option.doToast !== undefined) {
        state.doToast = option.doToast;
      }

      if (option.doOverlay !== undefined) {
        state.overlay = option.doOverlay;
      }
    }
  };

  /**
   * APIの実行
   *
   * @template T
   * @param {{
   *     conditions?: T; リクエストパラメーター
   *     url?: string; APIのURL
   *     ignorePageing?: boolean; ページングの条件を設定しない
   *     useSaveConditions?: boolean; 保持している検索条件ではなくconditionsの値をAPIのクエリパラメターとするか
   *   }} [paramaters]
   * @return {*}  {Promise<any>}
   */
  const loadData = async <T extends {}>(paramaters?: {
    conditions?: T;
    url?: string;
    ignorePageing?: boolean;
    useSaveConditions?: boolean;
  }): Promise<any> => {
    let params: any = {};

    if (paramaters && paramaters.url) {
      state.url = paramaters.url;
    }

    // conditionsを検索条件に利用する
    if (paramaters && paramaters.useSaveConditions === true) {
      state.useSaveConditions = paramaters.useSaveConditions;
    }

    // 初期検索時(searchCondition.value=='') または直前にコールしたAPIが異なる場合は保存値を使用しない
    if (state.useSaveConditions === true && baseStore.state.value.searchCondition.url !== state.url) {
      state.useSaveConditions = false;
    }

    if (paramaters && paramaters.ignorePageing) {
      state.ignorePageing = paramaters.ignorePageing;
    }

    if (state.useSaveConditions === true) {
      // 保存済の条件を利用
      params = baseStore.state.value.searchCondition.values;
    } else {
      params = state.ignorePageing ? {} : state.pagingParams;
      if (paramaters && paramaters.conditions) {
        params = {
          ...params,
          ...paramaters.conditions
        };
      }
      /** 検索条件を保持（詳細画面からの一覧に戻るときに利用） */
      if (state.saveConditions === true) {
        baseStore.setConditions(state.url, params);
      }
    }

    if (state.overlay) {
      baseStore.state.value.overlay = true;
    }

    state.loaded = false;

    // API実行
    // const data = await requestApi(state.url, params, { method: state.method }, true); 扱いが厄介だから使わない
    const data = await ofetchApi(state.url, params, { method: state.method }, true);

    // 検索条件を設定
    state.payload = params;

    // エラー
    if (!data || data.status !== 'success') {
      if (state.doToast) {
        // 予期せぬエラー発生
        baseStore.addToastMessage('データ取得に失敗しました', 'error');
        console.error('データ取得APIエラー', data);

        if (state.overlay) {
          baseStore.state.value.overlay = false;
        }

        state.loaded = true;

        return data;
      }
    }

    // レスポンスが一覧の場合
    if (data.result.list) {
      if (state.ignorePageing || !state.items) {
        state.items = data.result.list;
      } else if (state.items) {
        state.items.push(...data.result.list);
      }

      if (data.result.paging_data) {
        state.pagingData = data.result.paging_data;
        baseStore.setPagenationInfo(
          data.result.paging_data.total_pages,
          data.result.paging_data.total_rows,
          data.result.paging_data.page
        );
      }
    } else {
      state.item = data.result;
    }

    if (state.overlay) {
      baseStore.state.value.overlay = false;
    }

    state.loaded = true;

    // 呼び元でページング処理を使わない場合はstate.itemsではなくこのresultを使う
    return data;
  };

  /**
   * APIの実行(State保持しない)
   *
   * @template T
   * @param {string} url APIのURL
   * @param {T} conditions リクエストパラメーター
   * @param {string} method
   * @param {boolean} getFullResponse APIのレスポンスをすべて返すか
   * @param {boolean} doOverlay 実行中doOverlayするか
   * @param {boolean} token headerにtokenを設定するか
   * @param {object} option リクエストオプション
   * @return {*}  {Promise<any>}
   */
  const loadDataWoState = async <T extends {}>(
    url: string,
    conditions?: T,
    method?: string,
    getFullResponse?: boolean,
    doOverlay?: boolean,
    token?: boolean,
    apiOption?: {}
  ): Promise<any> => {
    let result = {};
    const params = conditions || {};
    const apiMethod = { method: method } || { method: 'get' };

    if (doOverlay) {
      baseStore.state.value.overlay = true;
    }

    // リクエストオプション
    const option = apiOption ? { ...apiMethod, ...apiOption } : apiMethod;
    const auth = token !== undefined && token === false ? false : true;
    const data = await ofetchApi(url, params, option, auth);

    if (data && data.status === 'success') {
      if (data.result) {
        if (getFullResponse) {
          result = data;
        } else {
          result = data.result;
        }
      }
    } else {
      console.error('データ取得APIエラー', data);
      baseStore.addToastMessage('データ取得に失敗しました', 'error');
      result = data;
    }

    if (doOverlay) {
      baseStore.state.value.overlay = false;
    }

    return result;
  };

  /**
   * ファイルの取得
   *
   * @template T
   * @param {string} url APIのURL
   * @param {T} conditions リクエストパラメーター
   * @param {string} accept リクエストheaders:Accept
   * @param {string} raw access raw response (for headers, etc), can use ofetch.raw
   * @return {*}  {Promise<any>}
   */
  const loadFile = async <T extends {}>(url: string, conditions?: T, accept?: string, raw?: boolean): Promise<any> => {
    const type = !accept ? accept : 'application/pdf';
    const rawResponse = !raw ? false : raw;
    const option = {
      headers: {
        Accept: type
      },
      method: 'get',
      responseType: 'blob'
    };
    baseStore.state.value.overlay = true;

    const params = conditions || {};
    const data = await ofetchApi(url, params, option, true, rawResponse);

    baseStore.state.value.overlay = false;

    return data ? data : null;
  };

  /**
   * 次のページのデータを取得
   * (２回ページングがされてしますので)
   */
  const NextPage = (): void => {
    if (!state.ignorePageing) {
      state.pagingParams.page++;
      if (state.pagingParams.page <= state.pagingData.total_pages) {
        loadData();
      }
    }
  };

  const resetItems = (): void => {
    state.items = [];
  };

  return {
    setSearchConfig,
    loadData,
    NextPage,
    loadDataWoState,
    loadFile,
    resetItems,
    ...toRefs(state)
  };
};

export { Search };
