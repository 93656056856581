<template>
  <!-- 要素がないとエラーになるためとりあえず入れた -->
  <p style="display: none">
    loading
  </p>
</template>

<script lang="ts">
import { useAuth } from '@/composables/auth';
export default defineComponent({
  setup() {
    definePageMeta({
      layout: false
    });
    useHead({ title: 'ログアウト' });

    const { logout } = useAuth();

    logout();
  }
});
</script>
