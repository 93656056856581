/**
 * APIリクエスト用
 */

import { ofetch } from 'ofetch';
import * as Sentry from '@sentry/vue';

export const useRequest = (): any => {
  const config = useRuntimeConfig();

  /**
   * Options (extends unjs/ofetch options & AsyncDataOptions):
   * method: Request method.
   * query: Adds query search params to URL using ufo
   * params: Alias for query
   * body: Request body - automatically stringified (if an object is passed).
   * headers: Request headers.
   * baseURL: Base URL for the request.
   */
  const optionDefault = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    },
    baseURL: config.public.baseUrl
  };

  /**
   *
   * ofetch
   *
   * url：url
   * condition：検索条件
   * options：リクエストオプション
   * token：ヘッダにAuthorization情報を設定するか
   *
   * setup外でも利用可能。paramatersはreactiveではないため値の変更でautore freshされない
   * @see https://npm.io/package/ohmyfetch
   */
  const requestApi = async (url: string, paramaters?: any, option?: any, token?: boolean): Promise<any> => {
    // リクエストオプション設定
    const options = {
      ...optionDefault,
      ...option
    };

    const { auth } = useAuth();
    if (token && auth.value.logedIn) {
      options.headers.Authorization = 'Bearer ' + auth.value.access_token;
    }

    if (options.method === 'get') {
      // 条件があればoptionに追加する
      if (Object.keys(paramaters).length > 0) {
        options.params = paramaters;
      }
    } else {
      if (Object.keys(paramaters).length > 0) {
        options.body = paramaters;
      }
    }

    const { data, error } = await useFetch(url, options);

    // エラー
    if (
      error.value?.response &&
      (!error.value.response.status || error.value.response.status === 409 || error.value.response.status === 500)
    ) {
      Sentry.captureException(error.value);
      return error.value.response._data;
    }

    // 成功
    return data.value;
  };

  /**
   * useFetch
   *
   * url：url
   * condition：検索条件
   * options：リクエストオプション
   * token：ヘッダにAuthorization情報を設定するか
   * raw：access raw response (for headers, etc), can use ofetch.raw
   *
   * setup内で利用可能。paramatersはreactiveで値の変更でauto refreshされる
   */
  const ofetchApi = async (
    url: string,
    paramaters?: any,
    option?: any,
    token?: boolean,
    raw?: boolean
  ): Promise<any> => {
    // リクエストオプション設定
    const options = {
      ...optionDefault,
      ...option
    };

    const { auth } = useAuth();
    if (token && auth.value.logedIn) {
      options.headers.Authorization = 'Bearer ' + auth.value.access_token;
    }

    if (options.method === 'get') {
      // 条件があればoptionに追加する
      if (Object.keys(paramaters).length > 0) {
        options.params = paramaters;
      }
    } else {
      if (Object.keys(paramaters).length > 0) {
        options.body = paramaters;
      }
    }

    if (raw) {
      const response = await ofetch.raw(url, options);
      if (response.status !== 200) {
        console.log(`*********** API実行エラー：${url}`);
      }
      return response;
    }

    const result = await ofetch(url, options).catch((error) => {
      console.log(`*********** API実行エラー：${url}`);

      if (error.response === undefined) {
        return { status: 'error', message: 'API実行エラー' };
      }

      // 特定のエラーレスポンスの場合はsentryへ通知
      if (!error.response.status || error.response.status === 409 || error.response.status === 500) {
        Sentry.captureException(error);
      }

      return error.response._data;
    });

    return result;
  };

  return {
    requestApi,
    ofetchApi
  };
};
