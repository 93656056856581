<template>
  <div>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<script lang="ts">
export default defineComponent({
  setup() {
    const { csrfToken, token } = useAuth();
    const setToken = async (): Promise<void> => {
      // cookieに設定
      token.value = await csrfToken();
    };
    setToken();
  }
});
</script>
