import { IAuth } from '@/types';
import { Search } from '@/composables/base-search';

const useAuth = (): any => {
  // cookieはRefオブジェクト
  const auth = useCookie('BA-ADMIN');
  const token = useCookie('BA-ADMIN-TOKEN');
  const { loadDataWoState } = Search();
  const baseStore = useBaseStore();

  // csrfトークン取得
  const csrfToken = async (): Promise<string> => {
    const data = await loadDataWoState('/csrf_token', {}, 'get', true, true, false);
    if (data.status === 'success') {
      // playwriteでcookieがうまく機能しないのでstateでtoken保持
      baseStore.state.value.token = data.result.csrf_token;
      return data.result.csrf_token;
    }
    return '';
  };

  // 認証URL取得
  const authorizationUrl = async (): Promise<string> => {
    const data = await loadDataWoState('/authorization_url', {}, 'get', true, true, false);
    return data.status === 'success' ? data.result.url : '';
  };

  // ログイン(codeとstateをセットしてリクエスト)
  const login = async (code: string, state: string): Promise<any> => {
    // 認証情報リセット
    clearAuth();

    // token取得実行(ユーザー情報取得)
    const data = await loadDataWoState(`/token?code=${code}&state=${state}`, {}, 'get', true, true, false);

    // エラー
    if (!data || data.status !== 'success') {
      // ログイン画面でエラーメッセージの判定を行うため値設定
      auth.value = 'invalid';
    } else {
      // cookieに認証情報書き込み
      setAuth(data.result);
    }
    return data;
  };

  // ID&PWDによるログイン
  const idPwdLogin = async (form: { id: ''; password: '' }): Promise<any> => {
    // 認証情報リセット
    clearAuth();

    const csrfToken = baseStore.state.value.token ? baseStore.state.value.token : token.value;

    // token取得実行(ユーザー情報取得)
    const data = await loadDataWoState(
      'login',
      { id: form.id, password: form.password, csrf_token: csrfToken },
      'post',
      true,
      true,
      false
    );

    // エラー
    if (!data || data.status !== 'success') {
      // ログイン画面でエラーメッセージの判定を行うため値設定
      auth.value = 'invalid';
    } else {
      // cookieに認証情報書き込み
      setAuth(data.result);
    }
    return data;
  };

  // ログアウト
  const logout = (): void => {
    clearAuth();
    loadDataWoState('/auth/logout', {}, 'get', true, true);
    navigateTo('/login');
  };

  // 認証情報登録
  const setAuth = (values: IAuth): void => {
    try {
      // cookieの書き換え
      values.logedIn = true;

      const permissions: any = {};
      for (const permission in values.userinfo.permissions) {
        // 参照権限以上の権限を持つ場合は権限を設定(参照権限のない権限は削除)
        if (values.userinfo.permissions[permission] > 0) {
          permissions[permission] = values.userinfo.permissions[permission];
        }
      }
      values.userinfo.permissions = permissions;
      auth.value = JSON.stringify(values);
    } catch (error) {
      clearAuth();
    }
  };

  // 認証情報クリア
  const clearAuth = (): void => {
    auth.value = '';
  };

  return {
    auth,
    token,
    csrfToken,
    login,
    idPwdLogin,
    logout,
    setAuth,
    clearAuth,
    authorizationUrl
  };
};

export { useAuth };
