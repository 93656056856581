<template>
  <BaseBreadcrumb
    title="ご利用明細書"
    :breadcrumbs="breadcrumbs"
  />
  <ProjectBaBankDetail :bank-code="bankCode" />
</template>

<script lang="ts">
export default defineComponent({
  setup() {
    definePageMeta({
      layout: 'default',
      middleware: ['authentication']
    });
    useHead({ title: 'ご利用明細書(インボイス)詳細' });

    const baseStore = useBaseStore();

    const breadcrumbs = ref([
      {
        text: 'ご利用明細書(インボイス)一覧',
        disabled: false,
        href: '/project/ba/invoice'
      },
      {
        text: 'ご利用明細書(インボイス)詳細',
        disabled: true,
        href: '#'
      }
    ]);

    // 権限設定
    baseStore.setPermissionName('project-ba-invoice');

    const route = useRoute();
    const bankCode = route.params.id;

    return {
      breadcrumbs,
      bankCode,
      baseStore
    };
  }
});
</script>
