import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import vueuse_head_polyfill_M7DKUOwKp5 from "/app/node_modules/nuxt/dist/head/runtime/plugins/vueuse-head-polyfill.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import vue_good_table_next_iQcRT5PZUY from "/app/plugins/vue-good-table-next.js";
import dayjs_pGiXRjcsJO from "/app/plugins/dayjs.ts";
import sentry_client_shVUlIjFLk from "/app/plugins/sentry.client.ts";
import utils_EEhlE5KdlH from "/app/plugins/utils.ts";
import vcalendar_s4MkH1KvHm from "/app/plugins/vcalendar.js";
import vuetify_7h9QAQEssH from "/app/plugins/vuetify.ts";
export default [
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  vueuse_head_polyfill_M7DKUOwKp5,
  router_jmwsqit4Rs,
  prefetch_client_5tzzN0oIVL,
  chunk_reload_client_UciE0i6zes,
  vue_good_table_next_iQcRT5PZUY,
  dayjs_pGiXRjcsJO,
  sentry_client_shVUlIjFLk,
  utils_EEhlE5KdlH,
  vcalendar_s4MkH1KvHm,
  vuetify_7h9QAQEssH
]