import { defineNuxtPlugin } from '#app';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime.js';
import 'dayjs/locale/ja';

dayjs.locale('ja');

export default defineNuxtPlugin((nuxtApp) => {
  dayjs.extend(relativeTime);
  nuxtApp.provide('dayjs', dayjs);
});
