import { defineNuxtPlugin } from '#app';
import dayjs from 'dayjs';
import { MAX_ROWS, PAGINATION_OPTIONS, FILE_STATUS } from '@/configs';
import { useAuth } from '@/composables/auth';
import { useBaseStore } from '@/composables/base-store';

/**
 * 各種文字列加工を行うユーティリティ郡等
 * @module utils/filter
 */
class Format {
  now(format: string): string {
    const value = dayjs().format();

    if (typeof format !== 'string') {
      format = 'YYYY-MM-DD HH:mm';
    }
    return dayjs(value).format(format);
  }

  /**
   * 日付のフォーマット
   *
   * @param {(string | null)} value 対象の文字列
   * @param {string} [format] フォーマット指定
   * @returns {string} フォーマットされた文字列
   */
  formatDate(value: string | null, format?: string): string {
    if (value == null || value === '') {
      return '';
    }
    if (typeof format !== 'string') {
      format = 'YYYY-MM-DD';
    }
    return dayjs(value).format(format);
  }

  /**
   * 日時のフォーマット
   *
   * @param {(string | null)} value 対象の文字列
   * @param {string} [format] フォーマット指定
   * @returns {string} フォーマットされた文字列
   */
  formatDateTime(value: string | null, format?: string): string {
    if (value == null || value === '') {
      return '';
    }
    if (typeof format !== 'string') {
      format = 'YYYY-MM-DD HH:mm';
    }
    return dayjs(value).format(format);
  }

  /**
   * 時間のフォーマット
   *
   * @param {(string | null)} value 対象の文字列
   * @param {string} [format] フォーマット指定
   * @returns {string} フォーマットされた文字列
   */
  formatTime = (value: string | null, format?: string): string => {
    if (value == null || value === '') {
      return '';
    }
    if (typeof format !== 'string') {
      format = 'HH:mm';
    }
    return dayjs(value).format(format);
  };

  /**
   * 月の減算
   *
   * @param {(string | null)} value 対象の文字列
   * @param {string} [format] フォーマット指定
   * @param {number} [per] 月数
   * @returns {string} フォーマットされた文字列
   */
  subtractMonth = (per: number, format?: string): string => {
    if (typeof format !== 'string') {
      format = 'YYYY-MM-DD HH:mm';
    }
    return dayjs().subtract(per, 'months').format(format);
  };

  /**
   * 文字列の長さ加工
   *
   * @param {any} value 対象文字列
   * @param {number} [specified_length] 返却する文字数
   * @param {string} [specified_omission] Suffix文字列
   * @returns {string} フォーマットされた文字列
   */
  truncate = (
    // eslint-disable-next-line
    value: any,
    // eslint-disable-next-line
    specified_length?: any,
    specified_omission?: string
  ): string => {
    const length = specified_length ? parseInt(specified_length, 10) : 20;
    const ommision = specified_omission ? specified_omission.toString() : '...';
    if (value === undefined || value === null) {
      value = '';
    }

    if (value.length <= length) {
      return value;
    }

    return value.substring(0, length) + ommision;
  };

  /**
   * 数値のフォーマット
   *
   * @param {(string | null)} value 対象の文字列
   * @returns {string} フォーマットされた文字列
   */
  formatNumber = (value: string): string => {
    if (value === undefined || value === null) {
      value = '';
    }
    if (!value) {
      value = '0';
    }
    return Number(value).toLocaleString();
  };

  /**
   * リンク文字列をhtmlリンクに変換
   *
   * @param {*} value 対象リンク文字列
   * @returns {string} 変換された文字列
   */
  linkify = (value: string): string => {
    try {
      let replacedText;

      const replacePattern1 = /(\b(https?):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim;
      replacedText = value.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');

      const replacePattern2 = /(^|[^/])(www\.[\S]+(\b|$))/gim;
      replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');

      const replacePattern3 = /(([a-zA-Z0-9\-_.])+@[a-zA-Z_]+?(\.[a-zA-Z]{2,10})+)/gim;
      return replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');
    } catch (e) {
      return value;
    }
  };

  /**
   * 端数を切り捨て
   *
   * @param {(number | null)} value 対象の文字列
   * @param {boolean} localeString　カンマ区切り指定
   * @returns {(number | string)} フォーマットされた数字
   */
  roundDown = (value: number, localeString: boolean): string | number => {
    if (value === undefined || value === null) {
      value = 0;
    }
    if (!value) {
      value = 0;
    }

    if (localeString) {
      return Math.floor(value).toLocaleString();
    }

    return Math.floor(value);
  };

  /**
   * 小数点第2位で切り捨て
   *
   * @param {(number | null)} value 対象の文字列
   * @param {boolean} localeString カンマ区切り指定
   * @returns {(number | string)} フォーマットされた数字
   */
  fixedPoint = (value: number, localeString: boolean): string | number => {
    if (value === undefined || value === null) {
      value = 0;
    }
    if (!value) {
      value = 0;
    }

    const num = Math.round(value * 100) / 100;

    if (localeString) {
      return num.toLocaleString();
    }

    return num;
  };
}

class Permission {
  /**
   * 編集淵源チェック
   *
   * @returns {boolean} 編集権限あり
   */
  write = (): boolean => {
    const { auth } = useAuth();
    const baseStore = useBaseStore();

    // ログインチェック
    if (auth.value === undefined || !auth.value.logedIn || !auth.value.userinfo) {
      return false;
    }
    return auth.value.userinfo.permissions[baseStore.state.value.permission] === 2;
  };
}

class Const {
  MAX_ROWS = MAX_ROWS;
  PAGINATION_OPTIONS = PAGINATION_OPTIONS;
  FILE_STATUS = FILE_STATUS;
  SYSTEM_ADMIN_ID = 1;
  SYSTEM_ADMIN_ROLE_ID = 2;

  STATUS = [
    {
      value: 0,
      text: '削除済'
    },
    {
      value: 1,
      text: '未公開'
    },
    {
      value: 2,
      text: '公開'
    }
  ];

  /**
   * ステータスのテキストを返却
   *
   * @param {(number)} value 対象の文字列
   * @returns {string} ステータスのテキスト
   */
  statusText = (value: number): string => {
    const tmp = this.STATUS.find((item: any) => item.value === value);
    return tmp?.text || '';
  };

  /**
   * システム管理者かどうか
   *
   * @returns {boolean} システム管理者かどうか
   */
  isSystemAdmin(): boolean {
    const { auth } = useAuth();
    // ログインチェック
    if (auth.value === undefined || !auth.value.logedIn || !auth.value.userinfo) {
      return false;
    }

    return auth.value.userinfo.id === this.SYSTEM_ADMIN_ID;
  }
}

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.provide('format', new Format());
  nuxtApp.provide('const', new Const());
  nuxtApp.provide('permission', new Permission());
});
