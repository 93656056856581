<template>
  <BaseBreadcrumb
    title="システム設定"
    :breadcrumbs="[]"
  />
  <DevSettingApp />
</template>

<script lang="ts">
export default defineComponent({
  setup() {
    definePageMeta({
      layout: 'default',
      middleware: ['authentication']
    });
    useHead({ title: 'システム設定' });

    const baseStore = useBaseStore();

    // 権限設定
    baseStore.setPermissionName('dev-setting-app');
  }
});
</script>
