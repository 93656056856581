// plugins/sentry.client.ts
// 参考：https://localazy.com/blog/nuxt-3-tailwind-i18n-eslint-starter#add-sentry

import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';

export default defineNuxtPlugin((nuxtApp) => {
  const { vueApp } = nuxtApp;

  const runtimeConfig = useRuntimeConfig();

  // https://docs.sentry.io/platforms/javascript/guides/vue/
  Sentry.init({
    app: [vueApp],
    dsn: runtimeConfig.public.sentryNuxtDsn,
    environment: process.env.NODE_ENV,
    // ローカル環境ではSentry通知をしない
    enabled: process.env.NODE_ENV !== 'local' && process.env.NODE_ENV !== 'development',
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(nuxtApp.$router)
      })
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0
  });
});
