<template>
  <!-- 要素がないとエラーになるためとりあえず入れた -->
  <p style="display: none">
    loading
  </p>
</template>

<script lang="ts">
import { useAuth } from '@/composables/auth';
export default defineComponent({
  setup() {
    definePageMeta({
      layout: false
    });

    const route = useRoute();
    const router = useRouter();

    const { login } = useAuth();

    // ログイン処理
    if (process.client && route.query.code) {
      login(route.query.code, route.query.state).then((data: any) => {
        if (!data || data.status !== 'success') {
          router.push('/login');
        } else {
          // トップ画面へredirect(最終的にはdashboardになるがリリースが先になるので金融機関一覧へ)
          router.push('/project/ba/bank');
        }
      });
    }
  }
});
</script>
