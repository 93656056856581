import { setupCalendar, Calendar, DatePicker } from 'v-calendar';
import 'v-calendar/style.css';

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(setupCalendar, {
    componentPrefix: 'V',
    color: 'blue',
    isDark: false,
    navVisibility: 'click',
    titlePosition: 'center',
    transition: 'slide-h',
    datePicker: {
      updateOnInput: true,
      inputDebounce: 1000,
      popover: {
        visibility: 'hover-focus',
        placement: 'bottom-start',
        isInteractive: true
      }
    },
    masks: {
      title: 'YYYY MMMM',
      weekdays: 'W',
      navMonths: 'MMM',
      hours: 'h A',
      input: ['L', 'YYYY-MM-DD', 'YYYY/MM/DD'],
      inputDateTime: ['L h:mm A', 'YYYY-MM-DD h:mm A', 'YYYY/MM/DD h:mm A'],
      inputDateTime24hr: ['L HH:mm', 'YYYY-MM-DD HH:mm', 'YYYY/MM/DD HH:mm'],
      inputTime: ['h:mm A'],
      inputTime24hr: ['HH:mm'],
      dayPopover: 'WWW, MMM D, YYYY',
      data: ['L', 'YYYY-MM-DD', 'YYYY/MM/DD'],
      model: 'iso',
      iso: 'YYYY-MM-DDTHH:mm:ss.SSSZ'
    }
  });
  nuxtApp.vueApp.component('VCalendar', Calendar);
  nuxtApp.vueApp.component('VDatePicker', DatePicker);
});
