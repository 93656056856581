<template>
  <BaseBreadcrumb
    title="相談詳細"
    :breadcrumbs="breadcrumbs"
  />
  <ProjectForumTopicDetail />
</template>

<script lang="ts">
export default defineComponent({
  setup() {
    definePageMeta({
      layout: 'default',
      middleware: ['authentication']
    });
    useHead({ title: '相談詳細' });

    const baseStore = useBaseStore();

    const breadcrumbs = ref([
      {
        text: '相談一覧',
        disabled: false,
        href: '/project/forum/topic'
      },
      {
        text: '相談詳細',
        disabled: true,
        href: '#'
      }
    ]);

    // 権限設定
    baseStore.setPermissionName('project-forum-topic');

    return {
      breadcrumbs
    };
  }
});
</script>
