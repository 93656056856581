<template>
  <BaseBreadcrumb
    title="相談一覧"
    :breadcrumbs="breadcrumbs"
  />
  <ProjectForumTopic />
</template>

<script lang="ts">
export default defineComponent({
  setup() {
    definePageMeta({
      layout: 'default',
      middleware: ['authentication'],
    });
    useHead({ title: '経営掲示板' });

    const breadcrumbs = ref([
      {
        text: '経営掲示板',
        disabled: true,
        href: '/project/forum/topic/detail/123'
      }
    ]);

    const baseStore = useBaseStore();

    // 権限設定
    baseStore.setPermissionName('project-forum-topic');

    return {
      breadcrumbs
    };
  }
});
</script>
